import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { useState, useEffect } from 'react';

const NotFound = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <header className="site-header d-flex flex-column justify-content-center align-items-center">
    <div className="not-found-container">
      <div className="not-found-card">
        <div className={`icon-container ${mounted ? 'bounce' : ''}`}>
          <AlertTriangle color="#f59e0b" size={64} />
        </div>
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>
          Oops! The page you're looking for doesn't exist or has been moved.
        </p>
        <a href="/" className="home-button">
          Go Home
        </a>
      </div>
      <style jsx>{`
        .not-found-container {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 1rem;
        }
        .not-found-card {
          max-width: 28rem;
          width: 100%;
          background-color: white;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
          border-radius: 0.5rem;
          padding: 2rem;
          text-align: center;
        }
        .icon-container {
          margin-bottom: 1.5rem;
        }
        .bounce {
          animation: bounce 1s infinite;
        }
        @keyframes bounce {
          0%, 100% { transform: translateY(-25%); animation-timing-function: cubic-bezier(0.8, 0, 1, 1); }
          50% { transform: translateY(0); animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
        }
        h1 {
          font-size: 2.25rem;
          font-weight: bold;
          color: #1f2937;
          margin-bottom: 1rem;
        }
        h2 {
          font-size: 1.5rem;
          font-weight: 600;
          color: #4b5563;
          margin-bottom: 1rem;
        }
        p {
          color: #6b7280;
          margin-bottom: 2rem;
        }
        .home-button {
          display: inline-block;
          background-color: #3b82f6;
          color: white;
          font-weight: bold;
          padding: 0.5rem 1rem;
          border-radius: 0.25rem;
          text-decoration: none;
          transition: background-color 0.3s ease;
        }
        .home-button:hover {
          background-color: #2563eb;
        }
      `}</style>
    </div>
    </header>
  );
};

export default NotFound;