import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

function Footer() {
  const location = useLocation();

  const handleScrollLinkClick = (e, section) => {
    if (location.pathname !== "/") {
      // Prevent default link behavior and change to desired page and hash
      e.preventDefault();
      window.location.href = `/#${section}`;
    }
  };

  return (
    <footer className="site-footer section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-12 mb-4 pb-2">
            <Link className="navbar-brand mb-2" to="/">
              <img src="../back (1).png" alt="nutriplan logo" />
              <span>Nutriplan</span>
            </Link>
          </div>

          <div className="col-lg-3 col-md-4 col-6">
            <h6 className="site-footer-title mb-3">Resources</h6>

            <ul className="site-footer-links">
              <li className="site-footer-link-item">
              <li className="site-footer-link-item">
                <ScrollLink
                  className="site-footer-link"
                  to="section_1"
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={(e) => handleScrollLinkClick(e, "section_1")}
                >
                  Home
                </ScrollLink>
              </li>
              </li>
              <li className="site-footer-link-item">
                <ScrollLink
                  className="site-footer-link"
                  to="section_2"
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={(e) => handleScrollLinkClick(e, "section_2")}
                >
                  Trackers
                </ScrollLink>
              </li>
              <li className="site-footer-link-item">
                <ScrollLink
                  className="site-footer-link"
                  to="section_3"
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={(e) => handleScrollLinkClick(e, "section_3")}
                >
                  How it works
                </ScrollLink>
              </li>
              <li className="site-footer-link-item">
                <ScrollLink
                  className="site-footer-link"
                  to="section_4"
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={(e) => handleScrollLinkClick(e, "section_4")}
                >
                  FAQs
                </ScrollLink>
              </li>
              <li className="site-footer-link-item">
                <ScrollLink
                  className="site-footer-link"
                  to="section_5"
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={(e) => handleScrollLinkClick(e, "section_5")}
                >
                  Contact
                </ScrollLink>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-4 col-6 mb-4 mb-lg-0">
            <h6 className="site-footer-title mb-3">Information</h6>

            <p className="text-white d-flex mb-1">
              <a href="https://nutriplan.seif8911.xyz" className="site-footer-link">
                Nutriplan
              </a>
            </p>

            <p className="text-white d-flex">
              <a href="mailto:nutriplan@seif8911.xyz" className="site-footer-link">
                Nutriplan@seif8911.xyz
              </a>
            </p>
          </div>

          <div className="col-lg-3 col-md-4 col-12 mt-4 mt-lg-0 ms-auto">
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                English
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button className="dropdown-item" type="button">
                    Arabic
                  </button>
                </li>
              </ul>
            </div>

            <p className="copyright-text mt-lg-5 mt-4">
              Copyright © 2024 Nutriplan. All rights reserved.
              <br />
              <br />
              Nutriplan Developed by{" "}
              <a rel="nofollow" href="https://nutriplan.seif8911.xyz" target="_blank">
                Web-Design Artists
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
