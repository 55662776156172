//imports
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Nav from '../src/components/navbar';
import Home from '../src/components/home';
import Plans from '../src/components/plans';
import Footer from "../src/components/footer";
import Purchase from '../src/components/purchase'
import Nutribot from "./components/nutribot";
import "../src/fonts/bootstrap-icons.woff"
import '../src/fonts/bootstrap-icons.woff2'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/css/main.css';
import '../src/css/bootstrap-icons.css';
import NotFound from "./components/notfound";
export default function Nutriplan_main() {
  return (
    <Router>
      <Nav />
      <Routes>
      <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/purchase" element={<Purchase/>}/>
        <Route path="/nutribot" element={<Nutribot/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
}
