import React, { useState } from "react";
import "../fonts/bootstrap-icons.woff";
import '../fonts/bootstrap-icons.woff2';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/main.css';
import '../css/bootstrap-icons.css';
import { db } from './firebase.js'; // Import Firestore from Firebase
import { v4 as uuidv4 } from 'uuid';

function Header() {
    return (
        <header className="site-header d-flex flex-column justify-content-center align-items-center">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Homepage</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Purchase</li>
                            </ol>
                        </nav>
                        <h2 className="text-white">Purchase Your Plan</h2>
                    </div>
                </div>
            </div>
        </header>
    );
}

function Section1() {
    const [isVisible, setIsVisible] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        plan: "",
        cardNumber: "",
        expiryDate: "",
        cvv: ""
    });
    const [formErrors, setFormErrors] = useState({});
    const [redirectUrl, setRedirectUrl] = useState("");

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        let formattedValue = value;

        // Handle card number input
        if (id === "cardNumber") {
            formattedValue = value.replace(/\D/g, "").slice(0, 16);
        }

        // Handle expiry date input (MM/YY format)
        if (id === "expiryDate") {
            formattedValue = value.replace(/\D/g, "").slice(0, 4);
            if (formattedValue.length >= 2) {
                formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`;
            }
        }

        // Handle CVV input
        if (id === "cvv") {
            formattedValue = value.replace(/\D/g, "").slice(0, 4);
        }

        setFormData({ ...formData, [id]: formattedValue });

        // Clear the error message as the user types
        if (formErrors[id]) {
            setFormErrors({ ...formErrors, [id]: "" });
        }
    };

    const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    const validateForm = () => {
        const errors = {};
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const cardNumberPattern = /^\d{16}$/;
        const expiryDatePattern = /^(0[1-9]|1[0-2])\/\d{2}$/;
        const cvvPattern = /^\d{3,4}$/;

        if (!formData.name) errors.name = "Name is required.";
        if (!formData.email || !emailPattern.test(formData.email)) errors.email = "Valid email is required.";
        if (!formData.plan) errors.plan = "Plan selection is required.";
        if (!formData.cardNumber || !cardNumberPattern.test(formData.cardNumber)) errors.cardNumber = "Valid card number is required.";
        if (!formData.expiryDate || !expiryDatePattern.test(formData.expiryDate)) errors.expiryDate = "Valid expiry date is required.";
        if (!formData.cvv || !cvvPattern.test(formData.cvv)) errors.cvv = "Valid CVV is required.";

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsVisible(true);

            const sessionTag = uuidv4();
            const baseUrl = isMobileDevice()
                ? "https://m.dashboard.nutriplan.seif8911.xyz"
                : "https://dashboard.nutriplan.seif8911.xyz";
            const redirectUrl = `${baseUrl}/login?purchase-successful&session=${sessionTag}`;
            setRedirectUrl(redirectUrl);

            try {
                await db.collection('purchases').add({
                    ...formData,
                    sessionTag,
                    usesLeft: 3,
                    timestamp: Date.now(),
                });

                // Redirect after 5 seconds
                setTimeout(() => {
                    window.location.href = redirectUrl;
                }, 5000);
            } catch (error) {
                console.error("Error storing purchase data: ", error);
            }
        }
    };

    return (
        <section className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-12 mx-auto">
                        <div className="custom-block custom-block-topics-listing bg-white shadow-lg mb-5">
                            <h3 className="text-center mb-4">Select Your Plan</h3>
                            <form id="purchase-form" onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        className="form-control"
                                        placeholder="Enter your name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {formErrors.name && <div className="text-danger">{formErrors.name}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        className="form-control"
                                        placeholder="Enter your email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {formErrors.email && <div className="text-danger">{formErrors.email}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="plan" className="form-label">Select Plan</label>
                                    <select
                                        id="plan"
                                        className="form-select"
                                        value={formData.plan}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="" disabled>Select a plan</option>
                                        <option value="basic">Basic - $15</option>
                                        <option value="plus">Plus - $30</option>
                                        <option value="premium">Premium - $50</option>
                                    </select>
                                    {formErrors.plan && <div className="text-danger">{formErrors.plan}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="cardNumber" className="form-label">Card Number</label>
                                    <input
                                        type="text"
                                        id="cardNumber"
                                        className="form-control"
                                        placeholder="Enter your card number"
                                        value={formData.cardNumber}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {formErrors.cardNumber && <div className="text-danger">{formErrors.cardNumber}</div>}
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label htmlFor="expiryDate" className="form-label">Expiry Date</label>
                                        <input
                                            type="text"
                                            id="expiryDate"
                                            className="form-control"
                                            placeholder="MM/YY"
                                            value={formData.expiryDate}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {formErrors.expiryDate && <div className="text-danger">{formErrors.expiryDate}</div>}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="cvv" className="form-label">CVV</label>
                                        <input
                                            type="text"
                                            id="cvv"
                                            className="form-control"
                                            placeholder="Enter CVV"
                                            value={formData.cvv}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {formErrors.cvv && <div className="text-danger">{formErrors.cvv}</div>}
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary">Pay Now</button>
                                </div>
                            </form>

                            <div id="confirmation-message" className={`alert alert-success mt-4 ${isVisible ? '' : 'd-none'}`}>
                                <h4 className="alert-heading">Purchase Confirmed!</h4>
                                <p>Your purchase has been successfully completed. You will be redirected to our dashboard shortly.</p>
                                <p>Your redirection link: <a href={redirectUrl}>{redirectUrl}</a></p>
                                <p>If you are not redirected within 10 seconds, please click the link above or contact support with the email and name you used to purchase your plan.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default function Purchase() {
    return (
        <>
            <Header />
            <Section1 />
        </>
    );
}

