// nutriplan, where health meets taste
import "../fonts/bootstrap-icons.woff";
import '../fonts/bootstrap-icons.woff2';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css'
import '../css/main.css';
import '../css/bootstrap-icons.css';
import React, { useState, useEffect } from "react";
import "bootstrap/dist/js/bootstrap.bundle"
import 'jquery'

import { Helmet } from "react-helmet";

const Demo = props => (
  <div>
    <Helmet>
      <script src="/js/custom-home.js" type="text/javascript"></script>
    </Helmet>
  </div>

);
function Section1() {
  
  return (
    <section className="hero-section d-flex justify-content-center align-items-center" id="section_1">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-12 mx-auto">
            <h1 className="text-white text-center">NutriPlan</h1>
            <h6 className="text-center">Where Health Meets Taste</h6>
          </div>
        </div>
      </div>
    </section>
  );
}

// About Us Section
function Section2() {
  return (
    <section className="featured-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-12 mb-4 mb-lg-0">
            <div className="custom-block bg-white shadow-lg">
              <div className="d-flex">
                <div>
                  <h5 className="mb-2">About us</h5>
                  <p className="mb-0">
                    Welcome to NutriPlan! We’re dedicated to making balanced nutrition
                    simple and personalized for everyone. Whether you’re managing dietary
                    restrictions, sticking to a budget, or fueling an active lifestyle, we provide
                    tailored meal plans and expert advice to meet your needs. Join us and transform
                    your health one meal at a time with NutriPlan.
                  </p>
                </div>
              </div>
              <img src="../NUTRIPLAN -1.png" className="custom-block-image img-fluid" alt="" />
            </div>
          </div>
          <div className="col-lg-6 col-12" id="mobnodisplay">
            <div className="custom-block custom-block-overlay">
              <div className="d-flex flex-column h-100">
                <img src="https://s3-alpha-sig.figma.com/img/3d21/133a/9a1a65e9d3b2b43d3a730de0c49b87af?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Lh2eGwN350epDwS67g1Y6ySKDKN4xW-NXwkM0~toU9monkgpU5JHzxFtBadY95~waSTqENtkBaLT6gWQE2pPc7FqGCv01anbadp61Y2vClm6H1XHfy8IUx1NcFnXWWLmAii4TIOHuSXpEQ-QpIaFZlxYLllW0FpQrzkmX1qQ1Pa~GdkJZTOSynGzJgNFZTWyDwQKdK~OHlycdF8vAtdc1gfUD58TLb7t6nNXO-jKlhuT20q3fLJot6AJSE7Ejd8FLh1Wm0m2u0EgtlpN94vF7SjRi96hcMEFB66W1RaUfKTQ6KxBdIj0~T0Z5f5J5As7Ml~yrNM-k-dQQZtYnuIkIg__" className="custom-block-image img-fluid" alt="" />
                <div className="custom-block-overlay-text d-flex">
                  <div>
                    <h5 className="text-white mb-2">Our Mission</h5>
                    <p className="text-white">
                      Our mission is to be your ultimate partner in achieving balanced and personalized nutrition. We believe that healthy eating should
                      be accessible, enjoyable, and tailored to your unique lifestyle. Whether you're planning meals on a budget or managing dietary restrictions, NutriPlan
                      is here to guide you every step of the way. Our mission is simple: to empower you with the tools and knowledge you need
                      to make informed food choices. Through our intuitive platform, we provide personalized meal plans, expert nutritional advice, and practical tips to
                      help you navigate the complexities of modern nutrition. Join us on the journey to better health, one meal at a time.
                      Eat smart, live well, and thrive with NutriPlan.
                    </p>
                  </div>
                </div>
                <div className="social-share d-flex">
                  <p className="text-white me-4">Share:</p>
                  <ul className="social-icon">
                    <li className="social-icon-item">
                      <a href="#" className="social-icon-link bi-twitter"></a>
                    </li>
                    <li className="social-icon-item">
                      <a href="#" className="social-icon-link bi-facebook"></a>
                    </li>
                    <li className="social-icon-item">
                      <a href="#" className="social-icon-link bi-pinterest"></a>
                    </li>
                  </ul>
                  <button id="tts-button" className="tts-button">
                    <i className="fa fa-volume-up"></i>
                  </button>
                </div>
                <div className="section-overlay"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// Trackers Section

function Trackers() {
  const [activeTab, setActiveTab] = useState('nutrition');
  const [inputValues, setInputValues] = useState({
    calorie: '',
    water: '',
    fruits: ''
  });
  const [responses, setResponses] = useState({});

  const handleInputChange = (category, value) => {
    setInputValues(prev => ({ ...prev, [category]: value }));
  };

  const handleSubmit = (category) => {
    const inputValue = inputValues[category];
    let responseText = "";

    switch (category) {
      case 'calorie':
        if (inputValue < 2000) {
          responseText = `You ate ${inputValue} calories today. Try to eat 2,200–3,000 for better activity and health and fitness.`;
        } else {
          responseText = `Great job on eating ${inputValue} calories today! Your body has definitely benefited from that.`;
        }
        break;
      case 'water':
        if (inputValue < 8) {
          responseText = `You drank ${inputValue} glasses of water today. Try to drink at least 8 glasses for better activity, health and fitness.`;
        } else {
          responseText = `Great job on drinking ${inputValue} glasses of water today! Your body has definitely benefited from that.`;
        }
        break;
      case 'fruits':
        if (inputValue < 2) {
          responseText = `You had ${inputValue} servings of fruits today. Aim for at least 2-3 servings daily.`;
        } else {
          responseText = `Great job on eating ${inputValue} servings of fruits today! Keep up the healthy eating.`;
        }
        break;
      default:
        responseText = "No data available for the selected category.";
    }

    setResponses(prev => ({ ...prev, [category]: responseText }));
  };

  const renderTracker = (category, title, placeholder) => (
    <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
      <div className="custom-block bg-white shadow-lg">
        <div className="d-flex">
          <div>
            <h5 className="mb-2">{title}</h5>
            <p className="mb-0">{placeholder}</p>
          </div>
          <span className="badge bg-design rounded-pill ms-auto">?</span>
        </div>
        <input
          type="number"
          className="form-control"
          placeholder="Enter value"
          value={inputValues[category]}
          onChange={(e) => handleInputChange(category, e.target.value)}
        />
        <div className="mt-4">
          <button className="btn btn-primary" onClick={() => handleSubmit(category)}>Submit</button>
        </div>
        {responses[category] && <p className="mt-3">{responses[category]}</p>}
      </div>
    </div>
  );

  const renderOtherTabContent = () => (
    <div className="col-12 text-center">
      <h3>Check out our other trackers in the dashboard</h3>
      <p>Visit <a href="https://dashboard.nutriplan.seif8911.xyz" target="_blank" rel="noopener noreferrer">dashboard.nutriplan.seif8911.xyz</a> for more trackers!</p>
    </div>
  );

  return (
    <section className="explore-section section-padding" id="section_2">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="mb-4">Browse Trackers</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {['Nutrition', 'Exercise', 'Sleep', 'Smoking', 'Stress'].map((tab, index) => (
              <li className="nav-item" role="presentation" key={tab}>
                <button
                  className={`nav-link ${activeTab === tab.toLowerCase() ? 'active' : ''}`}
                  onClick={() => setActiveTab(tab.toLowerCase())}
                  type="button"
                  role="tab"
                >
                  {tab}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="tab-content" id="myTabContent">
              <div className={`tab-pane fade ${activeTab === 'nutrition' ? 'show active' : ''}`} role="tabpanel">
                <div className="row">
                  {renderTracker('calorie', 'Calorie Intake', 'How many calories did you consume today?')}
                  {renderTracker('water', 'Water Intake', 'How many glasses of water did you drink today?')}
                  {renderTracker('fruits', 'Fruit Servings', 'How many servings of fruits did you eat today?')}
                </div>
              </div>
              {['exercise', 'sleep', 'smoking', 'stress'].map(tab => (
                <div key={tab} className={`tab-pane fade ${activeTab === tab ? 'show active' : ''}`} role="tabpanel">
                  {renderOtherTabContent()}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


//how does it work?
function Section3() {
  return (
    <section className="timeline-section section-padding" id="section_3">
      <div className="section-overlay"></div>

      <div className="container">
        <div className="row">

          <div className="col-12 text-center">
            <h2 className="text-white mb-4">How does it work?</h2>
          </div>

          <div className="col-lg-10 col-12 mx-auto">
            <div className="timeline-container">
              <ul className="vertical-scrollable-timeline" id="vertical-scrollable-timeline">
                <div className="list-progress">
                  <div className="inner"></div>
                </div>

                <li>
                  <h4 className="text-white mb-3">Search for your desired nutrition plan</h4>

                  <p className="text-white">At NutriPlan We offer a bunch of variety for all of our users,
                    whether you want to gain weight, maintain weight, lose wheight, or just need
                    help planning meals. Nutriplan has you covered.</p>

                  <div className="icon-holder">
                    <i className="bi-search"></i>
                  </div>
                </li>

                <li>
                  <h4 className="text-white mb-3">Purchase it &amp; And get access to our AI</h4>

                  <p className="text-white">After purchasing your desired plan, you will get a special
                    link to our AI which will help you choose your meals and keep in shape.</p>

                  <div className="icon-holder">
                    <i className="bi bi-credit-card-2-front"></i>
                  </div>
                </li>

                <li>
                  <h4 className="text-white mb-3">Read &amp; Enjoy</h4>

                  <p className="text-white">Once you get the special link you will signup, and now you can enjoy our AI and Dashboard
                    for free!</p>

                  <div className="icon-holder">
                    <i className="bi-book"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 text-center mt-5">
            <p className="text-white">
              Want to learn more?
              <a href="/plans" className="btn custom-btn custom-border-btn ms-3">Check out Our Plans</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

function Section4() {
  return (
    <section className="faq-section section-padding" id="section_4">
      <div className="container">
        <div className="row">

          <div className="col-lg-6 col-12">
            <h2 className="mb-4">Frequently Asked Questions</h2>
          </div>

          <div className="clearfix"></div>

          <div className="col-lg-5 col-12">
            <img src="../faq_graphic.jpg" className="img-fluid" alt="FAQs" />
          </div>

          <div className="col-lg-6 col-12 m-auto">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    What is Nutriplan
                  </button>
                </h2>

                <div id="collapseOne" className="accordion-collapse collapse show"
                  aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Nutriplan is an online platform dedicated to making balanced nutrition simple
                    and personalized for everyone. Whether you’re managing dietary restrictions,
                    sticking to a budget, or fueling an active lifestyle, we provide tailored meal
                    plans and expert advice to meet your need Join us and transform your health one
                    meal at a time with NutriPlan.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    How can i be apart of Nutriplan?
                  </button>
                </h2>

                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    You can simply purchase one of our plans and youll get access to a bunch of
                    custom tailored tools to help you with your balances nutrition.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    Does it need to paid?
                  </button>
                </h2>

                <div id="collapseThree" className="accordion-collapse collapse"
                  aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Nutriplan is a paid service. You can purchase one of our plans and get access to
                    a a wide variety of tools, Unfortunatly we cant just give out our service for
                    free as there are many factoring costs to running <strong>Nutriplan.</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

function Section5() {
  return (
    <section class="contact-section section-padding section-bg" id="section_5">
      <div class="container">
        <div class="row">
          <h2>Contact us</h2>
          <div class="col-lg-12 col-12">
            <h3 class="mb-4 pb-2">We'd love to hear from you</h3>
          </div>

          <div class="col-lg-6 col-12">
            <form action="#" method="post" class="custom-form contact-form" role="form">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-floating">
                    <input type="text" name="name" id="name" class="form-control" placeholder="Name"
                      required="" />

                    <label for="floatingInput">Name</label>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-floating">
                    <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*"
                      class="form-control" placeholder="Email address" required="" />

                    <label for="floatingInput">Email address</label>
                  </div>
                </div>

                <div class="col-lg-12 col-12">
                  <div class="form-floating">
                    <input type="text" name="subject" id="name" class="form-control"
                      placeholder="Name" required="" />

                    <label for="floatingInput">Subject</label>
                  </div>

                  <div class="form-floating">
                    <textarea class="form-control" id="message" name="message"
                      placeholder="Tell me about the project"></textarea>

                    <label for="floatingTextarea">Tell me about Nutriplan...</label>
                  </div>
                </div>

                <div class="col-lg-4 col-12 ms-auto">
                  <button type="submit" class="form-control">Submit</button>
                </div>

              </div>
            </form>
          </div>

          <div class="col-lg-5 col-12 mx-auto mt-5 mt-lg-0">
            <iframe class="google-map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1027.3100908039812!2d31.02550993881529!3d29.99536212590228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145850851b22a769%3A0xff18e1d3b07834f0!2sPalm%20Hills%2C%20First%206th%20of%20October%2C%20Giza%20Governorate!5e0!3m2!1sen!2seg!4v1723641061287!5m2!1sen!2seg"
              width="600" height="450" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>

            <h5 class="mt-4 mb-2">Nutriplan Center</h5>

            <p>Palm hils &amp;, First 6th of October, Giza Governorate, Egypt</p>
          </div>

        </div>
      </div>
    </section>
  )

}


export default function Home() {
  return (
    <>
    <Demo />
      <Section1 />
      <Section2 />
      <Trackers />
      <Section3 />
      <Section4 />
      <Section5 />
    </>
  )
};