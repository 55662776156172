import "../fonts/bootstrap-icons.woff"
import '../fonts/bootstrap-icons.woff2'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/main.css';
import '../css/bootstrap-icons.css';


function Section1(){
    return(
        <section class="section-padding">
            <div class="container">
             <center><h2>Chat with our Chatbot NutriBot</h2></center>
                <div class="custom-block custom-block-topics-listing bg-white shadow-lg mb-5">
                    <iframe id="container" src="https://nutribot.seif8911.xyz" frameborder="0" width="1116" height="500"></iframe>     
                </div>
            </div>
        </section>
    )
}

export default function Nutribot(){
    <Section1/>

}