 
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyA60B7kcgTUytcTm0f-xv0XhMncGabbxN8",
    authDomain: "nutriplan-firebase.firebaseapp.com",
    projectId: "nutriplan-firebase",
    storageBucket: "nutriplan-firebase.appspot.com",
    messagingSenderId: "40587415810",
    appId: "1:40587415810:web:9911afeefd55496e0fb21b",
    measurementId: "G-D6JQPRBXLW"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const storage = firebase.storage();
const db = firebase.firestore();

export { auth, db, storage };