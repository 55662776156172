import React from "react";
import "../fonts/bootstrap-icons.woff"
import '../fonts/bootstrap-icons.woff2'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/main.css';
import '../css/bootstrap-icons.css';

function Header() {
    return (
        <header class="site-header d-flex flex-column justify-content-center align-items-center">
            <div class="container">
                <div class="row align-items-center">

                    <div class="col-lg-5 col-12">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Homepage</a></li>

                                <li class="breadcrumb-item active" aria-current="page">Plans and Pricing</li>
                            </ol>
                        </nav>

                        <h2 class="text-white">Plans And Pricing</h2>
                    </div>

                </div>
            </div>
        </header>
    )

}

function Section1() {
    return (
        
        <section class="section-padding">
            <div class="container">
                <div class="row">

                    <div class="col-lg-12 col-12 text-center">
                        <h3 class="mb-4">Plans and Pricing</h3>
                    </div>

                    <div class="col-lg-8 col-12 mt-3 mx-auto">
                        <div class="custom-block custom-block-topics-listing bg-white shadow-lg mb-5">
                            <div class="d-flex">
                                <img src="basic.png" class="custom-block-image img-fluid" alt=""/>

                                    <div class="custom-block-topics-listing-info d-flex">
                                        <div>
                                            <h5 class="mb-2">Basic</h5>

                                            <p class="mb-0">The most basic plan, you will get 30 hours of ai, and exclusive waitlist bypass.</p>

                                            <a href="/purchase" class="btn custom-btn mt-3 mt-lg-4">Buy Now!
                                            </a>
                                        </div>

                                        <span class="badge bg-design rounded-pill ms-auto">15$</span>
                                    </div>
                            </div>
                        </div>

                        <div class="custom-block custom-block-topics-listing bg-white shadow-lg mb-5">
                            <div class="d-flex">
                                <img src="plus.png" class="custom-block-image img-fluid" alt=""/>

                                    <div class="custom-block-topics-listing-info d-flex">
                                        <div>
                                            <h5 class="mb-2">Plus</h5>

                                            <p class="mb-0">With the PLUS plan youll get 50 hours of AI usage, instant delivery, and exclusive access to our own custom trained AI</p>

                                            <a href="/purchase" class="btn custom-btn mt-3 mt-lg-4">Buy Now!</a>
                                        </div>

                                        <span class="badge bg-advertising rounded-pill ms-auto">30$</span>
                                    </div>
                            </div>
                        </div>

                        <div class="custom-block custom-block-topics-listing bg-white shadow-lg mb-5">
                            <div class="d-flex">
                                <img src="premium.png" class="custom-block-image img-fluid" alt=""/>

                                    <div class="custom-block-topics-listing-info d-flex">
                                        <div>
                                            <h5 class="mb-2">Premium</h5>

                                            <p class="mb-0">With our premium plan, you get all the previous benefits in addition to unlimited AI hours, Update priority, and access to our exclusive support to help you on your mission with <strong>Nutriplan!</strong></p>

                                            <a href="/purchase" class="btn custom-btn mt-3 mt-lg-4">Buy Now!</a>
                                        </div>

                                        <span class="badge bg-music rounded-pill ms-auto">50$</span>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default function Plans() {
    return (
        <>
        <Header />
        <Section1/>
        </>
        
    )
}