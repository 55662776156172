import React, { useEffect } from "react";
import "../fonts/bootstrap-icons.woff";
import "../fonts/bootstrap-icons.woff2";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/main.css";
import "../css/bootstrap-icons.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle"
import 'jquery'

import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const Demo = props => (
  <div>
              <Helmet>
              <script src="/js/jquery.sticky.js" type="text/javascript"></script>
              <script src="/js/click-scroll.js" type="text/javascript"></script>
              <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>              <script src="/js/click-scroll.js" type="text/javascript" />
              <script src="/js/custom-nav.js" type="text/javascript" />
              </Helmet>
          </div>
    
  );
function Navbar() {
  const location = useLocation();

  useEffect(() => {
    // Check if there's a hash in the URL, and scroll to it if present
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleScrollLinkClick = (e, section) => {
    if (location.pathname !== "/") {
      // Prevent default link behavior and change to desired page and hash
      e.preventDefault();
      window.location.href = `/#${section}`;
    }
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src="../back (1).png" alt="" />
          <span>NutriPlan</span>
        </Link>

        <div className="d-lg-none ms-auto me-4">
          <Link to="/login" className="navbar-icon bi-person smoothscroll"></Link>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-lg-5 me-lg-auto">

            {/* Use ScrollLink and handle the click to check if you need to navigate */}
            <li className="nav-item">
              <ScrollLink
                className="nav-link"
                to="section_1"
                smooth={true}
                duration={500}
                offset={-70}
                onClick={(e) => handleScrollLinkClick(e, "section_1")}
              >
                Home
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                className="nav-link"
                to="section_2"
                smooth={true}
                duration={500}
                offset={-70}
                onClick={(e) => handleScrollLinkClick(e, "section_2")}
              >
                Trackers
              </ScrollLink>
            </li>

            <li className="nav-item">
              <ScrollLink
                className="nav-link"
                to="section_3"
                smooth={true}
                duration={500}
                offset={-70}
                onClick={(e) => handleScrollLinkClick(e, "section_3")}
              >
                How it works
              </ScrollLink>
            </li>

            <li className="nav-item">
              <ScrollLink
                className="nav-link"
                to="section_4"
                smooth={true}
                duration={500}
                offset={-70}
                onClick={(e) => handleScrollLinkClick(e, "section_4")}
              >
                FAQs
              </ScrollLink>
            </li>

            <li className="nav-item">
              <ScrollLink
                className="nav-link"
                to="section_5"
                smooth={true}
                duration={500}
                offset={-70}
                onClick={(e) => handleScrollLinkClick(e, "section_5")}
              >
                Contact
              </ScrollLink>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/plans">
                Plans and Pricing
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default function Nav() {
  return (
    <>
      <Navbar />
      <Demo />
    </>
  );
}
